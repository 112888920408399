import styled from "@emotion/styled"

export default styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3rem;
  @media (max-width: 768px) {
    padding: 3rem 2rem;
  }

  @media (max-width: 576px) {
    padding: 3rem 1rem;
  }
`
